import React,{useEffect,useState} from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import TrackAdminForm from '../components/TrackAdminForm';
import trackAdminServices from '../trackAdminServices';

function TrackAdminList({AppAlert}) {

    const [trackList,setTrackList]      = useState(null);
    const [loading,setLoadingStatus]        = useState(false);

    const getTrackList = () => {
        setLoadingStatus(true);
        trackAdminServices.getList().then(res=>{
            setTrackList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getTrackList()
    },[])

    const remove = (id) =>{
        trackAdminServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getTrackList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                    <span className="float-end"><TrackAdminForm AppAlert={AppAlert} refreshList={getTrackList} /></span>
                    </div>
                </div>
            
                <div className="row mt-2">
                    <div className="col-md-12">
                        {trackList && trackList.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>TrackId</th>
                                        <th>Track Display Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trackList.map(track=>
                                        <tr>
                                            <td>{track.trackName}</td>
                                            <td>{track.trackTitle}</td>
                                            <td>
                                                <TrackAdminForm AppAlert={AppAlert} refreshList={getTrackList} data={track} />
                                                <ConfirmDialog id={track.id} confirm={remove} >Are you Sure You want to remove this track</ConfirmDialog>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {!trackList || trackList.length == 0 &&
                            <h4 >No track declared click on "Add New" button to declare new track</h4>
                        }
                    </div>
                </div>
            </div>
        
        </AppLayout>
        
    )
}

export default withAlert(TrackAdminList)