import React from 'react'
import { useParams } from 'react-router';
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';

function DeviceSettingPage() {
    let { id } = useParams();
    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <h3>device id  {id}</h3>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default withAlert(DeviceSettingPage)