import React, { useEffect, useState } from 'react'
import { Edit } from '@mui/icons-material'
import AppLayout from '../../layout/components/AppLayout'
import userServices from '../userServices';

import withAlert from '../../hoc/withAlert';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


/* Fromik */
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';
import ImageInline from '../../components/InlineEdit/ImageInline';


const UserAction = (props) =>{ 
    return (
        <>
            {props.data &&
                <Tooltip title="Update">
                    <IconButton onClick={()=>props.updateData(props.data)} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}




const UserListPage = ({AppAlert}) =>{

    
    // Form Work In Progress Start 

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [changePassword, setChangePassword] = useState(false);



    const initialValues = {
        email:"",
        password:"",
        firstName:"",
        lastName:"",
        role:"",
        workLocation:"",
    }

    const validationRule = Yup.object({
        email:Yup.string().required("Required"),
        firstName:Yup.string().required("Required"),
        workLocation:Yup.string().required("Required"),
        role:Yup.string().required("Required"),
    });

    const addNew = ()=>{
        setFormValues(initialValues);
        setChangePassword(true);
        setDialogStatus(true)
    }

    const updateData = (data)=>{
        setChangePassword(false);
        let autoFill = {
            id:data.id,
            email:data.email,
            password:"",
            firstName:data.firstName,
            lastName:data.lastName,
            workLocation:data.workLocation,
            profileImage:data.profileImage,
            role:data.role,
        }
        console.log(autoFill);
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    const onSubmit = (values, onSubmitProps) => {
        values.changePassword = changePassword;
        console.log(values);
        userServices.saveUser(values).then(res=>{
            AppAlert(res.msg);
            setDialogStatus(false);
            getUserList();
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })

    }

    // Form Work In progress End
    const [userList,setUserList] = useState(null);
    const [defaultColDef] = useState({
        floatingFilter: true,
        width:150,
        filter:true,
        sortable: true,
    })

    const [columnDefs] = useState([
        {field:"firstName", headerName: 'User Name', valueGetter: params => params.data.firstName+" "+params.data.lastName },
        {field:"email", },
        {field:"roleTitle", },
        {headerName: 'Action', cellRenderer: UserAction, width:150, cellRendererParams: {updateData}, filter:false, },
    ])

    const getUserList = () =>{
        userServices.getUserList().then((res)=>{
            setUserList(res.data);
        }).catch(error=>{
        })
    }

    /* Prepair User Role DD*/
    const [masters,setMasters] = useState(null);
    const getUserRoleDD = () => {
        userServices.getUserRoleDD().then(res=>{
                            
            setMasters({"user-role":res.data})
            getUserList();
        })
    }

    useEffect(()=>{
        getUserRoleDD();
    },[]);


    

    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <h5 className="float-start">Manage User Accounts</h5>
                        <span className="float-end"><button type="button" className="btn btn-primary" onClick={addNew}>Add New</button></span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        {userList && 
                            <div className="ag-theme-alpine" style={{height: 400, width:"auto"}}>
                                <AgGridReact
                                    rowData={userList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={(params)=>{ console.log(params)}}
                                    >
                                </AgGridReact>
                            </div>
                        }
                </div>
                </div>
            </div>


            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Manage User"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">

                                                    {formik.values.id  &&
                                                        <div className="col-md-12 text-center mb-2">
                                                            <ImageInline
                                                                label="User Logo"
                                                                name="profileImage"
                                                                value={formik.values.profileImage}
                                                                editId={formik.values.id}
                                                                updateInline={userServices.updateUserInline}
                                                                AppAlert={AppAlert}
                                                            />
                                                        </div>
                                                    }

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="First Name" name="firstName" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Last Name" name="lastName" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />

                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Email" name="email" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />


                                                    <FormikControl control="input" formik={formik} type="text" 
                                                        label="Counter No. / Room No / Work Station" name="workLocation" 
                                                        className="col-md-6 col-xs-12 p-2" 
                                                    />
    

                                                    {masters &&  
                                                        <FormikControl control="autoselect" formik={formik} 
                                                            label="Role" name="role"  
                                                            options={masters['user-role']} multiple={false} 
                                                            className="col-md-3 col-xs-6 p-2"
                                                        />
                                                    }

                                                    {!changePassword &&  <button type="button" className="btn btn-link" onClick={()=>setChangePassword(true)} >Change Password </button> }

                                                    {changePassword &&
                                                    
                                                        <FormikControl control="input" formik={formik} type="text" 
                                                            label="Password" name="password" 
                                                            className="col-md-6 col-xs-12 p-2" 
                                                        />
                                                    }

                                                </div>

                                            

                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        
                                        
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </AppLayout>
    )
}

export default withAlert(UserListPage);