import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AppLayout from '../../../layout/components/AppLayout'
import BusinessComponent from '../components/BusinessComponent'

export default function BusinessListPage() {

    const authUser = useSelector((state) => state.auth)


    
    return (
        <>
            {authUser.id && 
            <AppLayout>
                <div className="container">
                    <BusinessComponent userId={authUser.id}/>
                </div>
            </AppLayout>
            }
        </>
  )
}
