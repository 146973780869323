import React, { useState,useEffect } from 'react'
import businessServices from '../businessServices';


import BusinessForm from './BusinessForm';
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import ProfileImageInlineAws from '../../../components/InlineEdit/ProfileImageInlineAws';


const BusinessDetails = ({label,value}) =>{
    return (
    <div className="row">
        <div className="col-md-6">
            <strong>{label}</strong>
        </div>
        <div className="col-md-6">
            <span>{value}</span>
        </div>
    </div>
    )
}


export default function BusinessComponent({userId}) {
    const [businessList, setBusinessList] = useState(null)
    const [loadingInProgress, setLoadingStatus] = useState(false);
    

    

    const getBusinessList = () => {
        setLoadingStatus(true);
        businessServices.getListByUserId(userId).then(res => {
            res.data.length > 0 ? setBusinessList(res.data) : setBusinessList(null);
            setLoadingStatus(false);
        }).catch(error=>{
            setLoadingStatus(false);
        })
    }


    const [masters,getMasterLabel] = useSystemMasterList(["business-type"]);

    
    useEffect(() => {
        getBusinessList();
    }, [])

    return (
      <>
        {!loadingInProgress &&
            <div className=" mt-2" id="business">
                
                <div className="card-body">
                        {businessList &&
                            <>
                                {
                                    businessList.map(business => 
                                        <div key={business.id}>

                                            <div className="row" >
                                                <div className="col-md-3">
                                                    
                                                    {/*
                                                    <ProfileImageInlineAws 
                                                        label={"Logo"}
                                                        editId={business.id} 
                                                        name="logo" 
                                                        value={business.logo} 
                                                        updateInline={businessServices.update}
                                                        fileId={business.id}
                                                        module="business"
                                                    />
                                                    */
                                                    }


                                                </div>
                                                <div className="col-md-9">
                                                    { /*
                                                        <InlineTextEdit editId={business.id}  name="title" 
                                                        handleInlineChange={businessServices.update}  
                                                        label="Business Name" value={business.title}  
                                                    />
                                                    <InlineTextEdit editId={business.id}  name="gst" 
                                                        handleInlineChange={businessServices.update}  
                                                        label="Business GST" value={business.gst}  
                                                    />
                                                    <InlineTextEdit editId={business.id}  name="pan" 
                                                        handleInlineChange={businessServices.update}  
                                                        label="Business PAN" value={business.pan}  
                                                    />
                                                    */}
                                                    <BusinessForm userId={userId} refreshList={getBusinessList} data={business}  masters={masters}/>
                                                    <BusinessDetails label="Business Name" value={business.title}/>
                                                    <BusinessDetails label="Business Owner" value={business.businessOwner}/>
                                                    <BusinessDetails label="Business GST" value={business.gst}/>
                                                    <BusinessDetails label="Business Pan" value={business.pan}/>
                                                    
                                                    <BusinessDetails label="Business Phone" value={business.businessPhone}/>
                                                    <BusinessDetails label="Business Email" value={business.businessEmail}/>
                                                    {masters &&  <BusinessDetails label="Business Type" value={getMasterLabel("business-type",business.businessType)}/>  }
                                                </div>
                                            </div>
                                            {/*
                                            
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ContactComponent  userId={userId} module="2" moduleId={business.id} titleLine="Business Contact Details" limit={3} />
                                                </div>
                                                <div className="col-md-12">
                                                   <AddressComponent userId={userId} module="2" moduleId={business.id} titleLine="Business Address" limit={3}/> 
                                                </div>
                                                <div className="col-md-12">
                                                    <DocumentComponent userId={userId} module="2" moduleId={business.id} titleLine="Business Documents" limit={5} />
                                                </div>
                                                
                                            </div>
                                            */}
                                        </div>
                                    )
                                }
                            </>
                        }

                        
                        {!businessList &&
                            <div className="row">
                                <div className="center"><h6>No Business Details Exist <BusinessForm userId={userId} refreshList={getBusinessList}  masters={masters}/> </h6></div>
                            </div>
                        }
                </div>
            </div>
        }
      </>
    )
}