
import React, { useEffect, useState,useCallback,useRef } from 'react'
import AppLayout from '../../../../layout/components/AppLayout';
import reportServices from '../reportServices';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Download } from '@mui/icons-material';
import FilterComponent from '../components/FilterComponent';

function ReportPage() {

    const [log,setLog] = useState(null);
    const getLog = () =>{
        reportServices.getLog().then(res=>{
            //setLog(res.data);
        })
    }
    useEffect(()=>{
        getLog()
    },[])


    /*   grid Work */
    
    const gridRef = useRef();
    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
    })
    const [columnDefs,setColumnDefs] = useState([]);
    const setupColDef = () =>{
        setColumnDefs([
            {field:"created_at",headerName:"Date Time",width:180},
            {field:"Site_ID",headerName:"Site ID"},
            {field:"deviceTitle",headerName:"Device"},
            {field:"trackTitle",headerName:"Track"},
            {field:"T_PT",headerName:"Track Play Time"},
            {field:"H_PT",headerName:"Hooter Play Time"},
            {field:"A_PT",headerName:"All Play Time"},
            {field:"TS",headerName:"Track Status", valueGetter: params => params.data.TS === 1 ? "Played": "Stopped" },
            //{field:"HS",headerName:"Hooter Status", valueGetter: params => params.data.HS === 1 ? "Played" : "Stopped"},
            {field:"TEMP",headerName:"Temp"},

        ]);
    }

    const rowClassRules = {
        //'bg-primary text-white' : params => params.data.status === 1,
        'bg-warning' : params => params.data.TS === 1,
        //'bg-info': params => params.data.status === 3,
        'bg-success': params => params.data.TS === 0,
        //'bg-danger': params => params.data.status === 5,
        //'bg-dark text-white': params => params.data.status === 6,
        
        
        
        
    };

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv("data.csv");
    }, []);

    /* Grid Work */

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                        <FilterComponent setLog={setLog} setupColDef={setupColDef} />
                    </div>
                </div>
                {log !== null &&
                <div className="row">
                        <div className="col-md-12 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"><Download/>  Export</button>
                        </div>
                        <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 450, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={log}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    animateRows={true}
                                    rowClassRules={rowClassRules}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                }
                </div>
        </AppLayout>
    )
}

export default ReportPage