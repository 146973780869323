import React,{useEffect,useState} from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import SiteAdminForm from '../components/SiteAdminForm';
import siteAdminServices from '../siteAdminServices';

function SiteAdminList({AppAlert}) {

    const [siteList,setSiteList]      = useState(null);
    const [loading,setLoadingStatus]        = useState(false);

    const getSiteList = () => {
        setLoadingStatus(true);
        siteAdminServices.getList().then(res=>{
            setSiteList(res.data);
        }).catch(error=>{
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getSiteList()
    },[])

    const remove = (id) =>{
        siteAdminServices.remove(id).then(res=>{
            AppAlert("Removed Successfully","error")
            getSiteList();
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-md-12">
                    <span className="float-end"><SiteAdminForm AppAlert={AppAlert} refreshList={getSiteList} /></span>
                    </div>
                </div>
            
                <div className="row mt-2">
                    <div className="col-md-12">
                        {siteList && siteList.length > 0 &&
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>SiteId</th>
                                        <th>Site Display Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {siteList.map(site=>
                                        <tr>
                                            <td>{site.siteName}</td>
                                            <td>{site.siteTitle}</td>
                                            <td>
                                                <SiteAdminForm AppAlert={AppAlert} refreshList={getSiteList} data={site} />
                                                <ConfirmDialog id={site.id} confirm={remove} >Are you Sure You want to remove this site</ConfirmDialog>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {!siteList || siteList.length == 0 &&
                            <h4 >No site declared click on "Add New" button to declare new site</h4>
                        }
                    </div>
                </div>
            </div>
        
        </AppLayout>
        
    )
}

export default withAlert(SiteAdminList)