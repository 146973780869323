import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
const authURL = process.env.REACT_APP_AUTH_URL;

//let portalUrl = localStorage.getItem("portalUrl");
//const baseURL = portalUrl;
//const authURL = portalUrl;


const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
});


API.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            let token = authUser.token;
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['X-Requested-With'] = 1;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
    error => Promise.reject(error)
);


export const authAPI = axios.create({
    baseURL: authURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

authAPI.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            let token = authUser.token;
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['X-Requested-With'] = 1;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
    error => Promise.reject(error)
);
  

export default API;






/*

import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
});


API.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            let token = authUser.token;
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['X-Requested-With'] = 1;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
  
    error => Promise.reject(error)
  );
  
  export default API;

*/